@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: 'HortaRegular';

 
}
/*https://fontlibrary.org/es/font/horta*/
code {
  /* font-family: 'Kanit', sans-serif; */
  font-family: 'HortaRegular';
   font-weight: normal;
   font-style: normal;
   

}


.textos {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  
}
.texto-footer {
  font-family: Arial, Helvetica, sans-serif;
  font-weight: 600;
  
  
}

.hero-slider-overlay {
  color: white;
  background-color: rgba(0, 34, 78, 0);
  width: 100%;
}

.link-underline {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 0 3px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
}

.link-underline-black {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff)
}

.link-underline:hover {
  background-size: 100% 3px;
  background-position: 0 100%
}

.link-underline-footer {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(#fff, #fff);
  background-size: 100% 0;
  padding: 5px 5px 5px 5px;
  background-position: 0 100%;
  background-repeat: no-repeat;
  transition: background-size .3s ease-in-out;
  border-radius: 8px;
}
.link-underline-footer:hover {
  background-size: 100% 100%;
  background-position: 0 100%;
  color: #0c234c;
  border-radius: 8px;
}

/*responsive menu*/
.link-underline-responsive {
  border-bottom-width: 0;
  background-image: linear-gradient(transparent, transparent), linear-gradient(-72deg,
  #dedeff,
  #ffffff 16%,
  #dedeff 21%,
  #ffffff 24%,
  #555564 27%,
  #dedeff 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedeff 72%,
  #ffffff 80%,
  #dedeff 84%,
  #555564);
  background-size: 0 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
  
}

.link-underline-black-responsive {
  background-image: linear-gradient(transparent, transparent), linear-gradient(#7DB6D5, #7DB6D5);
}

.link-underline-responsive:hover {
  background-size: 100% 100%;
  background-position: 100% 100%;
  color: #0C2348;
}




.underlineComp {
  border-bottom: 50px solid transparent;
  border-image: linear-gradient(-72deg,
  #dedeff,
  #ffffff 16%,
  #dedeff 21%,
  #ffffff 24%,
  #555564 27%,
  #dedeff 36%,
  #ffffff 45%,
  #ffffff 60%,
  #dedeff 72%,
  #ffffff 80%,
  #dedeff 84%,
  #555564);
    border-image-slice: 1;
    width:100%;
  
}
.heroBGC{
  background: #7DB6D5;
  background: radial-gradient(circle, #7DB6D5 0%, #0C2348 60%);
 
  
}

.particlesBack {
  z-index: -2;
}

.iconosFooter{
 margin-left: 0;
 font-size: 2rem;
 justify-content: space-around;
 display:flex;
 color: #fff;
 width: 100%;
}

.button-platinum {
  color: rgba(0, 0, 0, 0.72);
  border-radius: 50%;
  height: 30px;
  width: 30px;
  text-decoration: none;
  font-weight: bold;
  text-shadow: 1px 1px 0 #ffffff;
  box-shadow:
    2px 2px 0.5em rgba(122, 122, 122, 0.55),
    inset 1px 1px 0 rgba(255, 255, 255, 0.9),
    inset -1px -1px 0 rgba(0, 0, 0, 0.5)
  ;
  border: 1px solid #cacade;
  background:
    -moz-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  -webkit-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  -o-linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
  background:
  linear-gradient(
    -72deg,
    #dedeff,
    #ffffff 16%,
    #dedeff 21%,
    #ffffff 24%,
    #555564 27%,
    #dedeff 36%,
    #ffffff 45%,
    #ffffff 60%,
    #dedeff 72%,
    #ffffff 80%,
    #dedeff 84%,
    #555564
  );
}


.listaPlateada{
  /* position: relative;
 height: 50px;
  margin-top: 93vh; */
  background-image: linear-gradient(transparent, transparent), linear-gradient(-72deg,
  #dedeff,
  #ffffff 16%,
  #dedeff 21%,
  #ffffff 24%,
  #555564 27%,
  #dedeff 36%,
  #ffffff 45%,
  #4a4a55 60%,
  #555564 72%,
  #ffffff 80%,
  #dedeff 84%,
  #555564);
  background-size: 100%;
  background-position: 100% 100%;
  background-repeat: no-repeat;
  transition: background-size .5s ease-in-out;
  
}
  
/*Blinking arrow*/
.blink {
  
  animation: blink .8s linear infinite;
    
    
  }
  @keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .7;}
    100%{opacity: 1;}
    }

    .blink {
  
  animation: blink .8s linear infinite;
    
    
  }
  @keyframes blink{
    0%{opacity: 0;}
    50%{opacity: .7;}
    100%{opacity: 1;}
    }

    
    
.blinking-moon {
  animation: blinker .5s linear infinite; 
 
}
@keyframes blinker{
   80%{opacity: .8;}
   /* 50%{opacity: .7;} */
  100%{opacity: 1;}
  
}

